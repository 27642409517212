@media screen and ( max-width: 650px ){
    #srvcs-cont-ttl{
        margin: 25px 0px;
    }
    #srvcs-cont-cat-sec{
        column-gap: 10px;
    }
}

@media screen and ( max-width: 550px ){
    #srvcs-cont-ttl{
        margin: 0px 0px 0p 0px;
    }
    #srvcs-cont-cat-sec{
        column-gap: 10px;
    }
}

@media screen and ( max-width: 450px ){
    #srvcs-cont-ttl{
        margin: 0px 0px 0p 0px;
    }
    #srvcs-cont-cat-sec{
        column-gap: 10px;
    }
}