#nv-br-cont{
    align-items:center;
    justify-content:space-evenly;
    background-color: rgb(255, 128, 0);
    color: white;
}

.nv-lnk{
    font-size: larger;
    font-weight: 600;
    padding: 15px 25px;
    color: white;
    text-decoration: none;
    border-style: solid;
    border-width: 5px 0px;
    border-radius: 0px;
    border-color: rgb(255, 128, 0);
}

.nv-lnk:hover{
    border-style: solid;
    border-color: #00319b;
    border-width: 5px 0px;
    border-radius: 0px;
}