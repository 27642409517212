@media screen and ( max-width: 600px ){
    
    .main-cont-column-set{
        height: 250px;
        width: 90%;
        border-radius: 10px;
    }
    
    #main-cont-ntr-sec{
        width: 80%;
        margin-top: 25px;
    }
    
    #main-cont-info-sec{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 100px;
    }
    
    .main-cont-info-prt{
        width: 260px;
        height: 260px;
        position: relative;
    }
    
    .info-prt-image{
        position: absolute;
        top: -20%;
        left: 35%;
        height: 100px;
        background-color: white;
    }
    
    .info-prt-title{
        color: rgb(255, 128, 0);
    }
    
    .info-prt-dscrptn{
        color: grey;
        margin-top: 25px;
        line-height: 20px;
        font-size: small;
    }

    #mn-cont-column-1{
        height: 300px;
    }

    #mn-cont-column-2{
        height: 250px;
    }

    #mn-cont-column-3{
        height: 200px;
    }

    #mn-cont-column-4{
        height: 250px;
    }

    #mn-cont-column-5{
        height: 300px;
    }
    
    
}