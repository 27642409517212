@media screen and ( max-width: 1200px){
    #hdr-cont-image-one{
        left: -45%;
    }
}

@media screen and ( max-width: 1000px){
    #hdr-cont-image-one{
        left: -55%;
    }
}

@media screen and ( max-width: 800px){
    #hdr-cont{
        height: 25vh;
    }
    #hdr-cont-lgo{
        left: 5%;
    }
    #hdr-cont-image-one{
        left: -60%;
    }
}

@media screen and ( max-width: 600px){
    #hdr-cont{
        height: 20vh;
    }
    #hdr-cont-image-one{
        left: -60%;
    }
}

@media screen and ( max-width: 500px){
    #hdr-cont{
        height: 15vh;
    }
    #hdr-cont-image-one{
        left: -60%;
    }
}