#srvcs-cont{
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    margin-bottom: 50px;
    column-gap: 0px;
    width: 100%;
}

#srvcs-cont-ttl{
    flex: 100%;
    width: 100%;
    margin: 50px 0px;
    color: rgb(70, 70, 70);
}
#srvcs-cont-sec-one{
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 25px;
    justify-content: center;
    padding: 0px;
    margin: 0px;
}

#srvcs-category-title:hover{
    color: white;
    background-color: #00319b;
    cursor: pointer;
}

#srvcs-cont-cat-sec{
    flex-wrap: wrap;
    column-gap: 10px;
    width: 85%;
    justify-content: center;
    margin-bottom: 50px;
    text-align: center;
}

.services-product{
    font-weight: 500;
}