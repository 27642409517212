#services-card-cont{
    background-position: center;
    background-size: cover;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 40vh;
    box-shadow: 0 10px 20px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
}