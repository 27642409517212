#main-cont{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#main-cont-column-sec{
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: no-wrap;
    margin-top: 50px;
    width: 100%;
}

.main-cont-column-set{
    width: 20%;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 0px 5px;
}

#mn-cont-column-1{
    height: 600px;
    background-image: url('./image/bluePallets.png');
    background-size: cover;
    background-position: center;
}

#mn-cont-column-2{
    background-image: url('./image/greenPallets.png');
    background-size: cover;
    background-position: center;
    height: 550px;
}

#mn-cont-column-3{
    background-image: url('./image/palletTrailer.png');
    background-size: cover;
    background-position: center;
    height: 450px;
}

#mn-cont-column-4{
    background-image: url('./image/greyPallets.png');
    background-size: cover;
    background-position: center;
    height: 550px;
}

#mn-cont-column-5{
    height: 600px;
    background-image: url('./image/orangePallet.png');
    background-size: cover;
    background-position: center;
}


#main-cont-ntr-sec{
    width: 70%;
    line-height: 30px;
    font-weight: 600;
    font-size: larger;
    text-align: center;
    margin-top: 50px;
}

#main-cont-info-sec{
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 100px 0px;
    column-gap: 125px;
    row-gap: 75px;
}

.main-cont-info-prt{
    border-color: #00319b;
    border-width: 5px;
    border-style: ridge;
    border-radius: 200px;
    width: 300px;
    height: 300px;
    padding: 25px;
    position: relative;
    box-shadow: 0 20px 20px rgb(0 0 0 / 0.2);
}

.info-prt-image{
    position: absolute;
    top: -15%;
    left: 35%;
    height: 100px;
    background-color: white;
}

.info-prt-title{
    color: rgb(255, 128, 0);
}

.info-prt-dscrptn{
    color: grey;
    margin-top: 50px;
}

