.App {
  text-align: center;
}

.container{
  display: flex;
}

#nv-line{
  width: 100%;
  background-color: #00319b;
  height: 5px;
}