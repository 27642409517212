@media screen and ( max-width: 650px ){
    #services-card-cont{
        width: 250px;
    }
}

@media screen and ( max-width: 550px ){
    #services-card-cont{
        width: 200px;
        height: 30vh;
    }
}

@media screen and ( max-width: 450px ){
    #services-card-cont{
        width:150px;
        height: 20vh;
    }
}