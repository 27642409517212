#hdr-cont{
    height: 30vh;
    width: 100%;
    justify-content: flex-end;
}

#hdr-cont-image-sec{
    width: 100%;
    height: 100%;
    justify-content: end;
    align-items: center;
    position: relative;
}

#hdr-cont-lgo{
    position: absolute;
    height: 100%;
    left: 10%;
}

#hdr-cont-image-one{
    position: absolute;
    left: -35%;
    height: 100%;
}

#hdr-cont-image-two{
    position: relative;
    background-image: url('./images/palletStackB1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 50%;
}