#ftr-cont{
    flex-direction: column;
    background-color: rgb(255, 128, 0);
    color: white;
    padding-bottom: 25px;
}

#cntct-info-sec{
    width: 100%;
}

#cntct-info-sec-one{
    flex-direction: column;
    width: 100%;
}

#cntc-info-sec-one-info{
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 50px;
}

.cntc-info-sec-one-description{
    flex-direction: column;
    justify-content: center;
    align-items:    center;
    width: 50%;
}

.cntct-icon{
    width: 100px;
    height: 100px;
}

#contact-email{
    position: relative;
}

#tooltip-email{
    display: none;
    position: absolute;
    background-color: rgb(43, 43, 43);
    font-weight: 200;
    left: 20%;
    top: -120%;
    padding: 0px 10px;
    border-radius: 5px 5px 5px 5px;
    font-size: smaller;
    width: 50%;
    white-space: nowrap;
}

#tooltip-email::before{
    content: "";
    position: absolute;
    left: 53%;
    top: 100%;
    transform: translateX(-50%);
    border: 5px solid;
    border-color: #000 #0000 #0000 #0000;
}

#contact-email:hover #tooltip-email{
    display: block;
}

#cprght{
    color: rgb(43, 43, 43);
    align-self: center;
}

#phn-lnk{
    text-decoration: none;
    color: white;
}

.cntct-info-sec{
    font-weight: 700;
}