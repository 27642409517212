@media screen and ( max-width: 600px){
    #cntc-info-sec-one-info{
        display: flex;
        flex-direction: column;
    }
    #cprght{
        padding: 0px 10px;
    }
}

@media screen and ( max-width: 500px){
    #cntc-info-sec-one-info{
        display: flex;
        flex-direction: column;
    }
    #cprght{
        width: 75%;
    }
}